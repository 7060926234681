export const environment = {
  chatperk_sdk: 'https://cdn.chatperk.ai/sdk/chatperk-sdk-dev.min.js',
  google_api_key: '',
  places_key: '',
  production: false,
  version: '0.0.1 (beta)',
  graphql_endpoint: 'https://chatperk-api.storexweb.team/graphql',
  url: 'https://chatperk-files.storexweb.team',
  skip_auth: false,
  integrations: {
    slack_redirect_url:
    'https://slack.com/oauth/v2/authorize?client_id=7692554131765.7718576554576&scope=app_mentions:read,channels:history,channels:manage,channels:read,chat:write,chat:write.public,commands,files:read,files:write,groups:history,groups:read,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,pins:read,reactions:read,reactions:write,users:write,channels:join&user_scope=team:read,users:read,users:read.email,usergroups:read,emoji:read,dnd:read',
    meta_app_id: '1502953207010133',
    whatsapp_config_id: '897066068679630',
    messenger_config_id: '8515533035208604',
    instagram_config_id: '27779841204963499'
  },
  firebase: {},
  STRIP_PUBLISHABLE_KEY:'pk_test_51Q1PMzKvhXcNDawwBpjhcRPwKsT71kimLGZfJBzhovx72Xpr2J1FtleaMeJiN5KX3hqIOdCOs7Gpmhd7Q7upwIO900QBLTXWvC',
  STRIPE_REDIRECT_URL: 'https://app.chatperk.ai/space/payment-status',
  annualDiscount: 2, //TODO move to another place
  annualDiscountText: '2 Months', //TODO move to another place
  website_url: "https://chatperk.ai",
  immediateInvoice: {
    
  },
  APP_SUMO_REVIEW_LINK: "https://appsumo.com/products/chatperk/#reviews"
};
